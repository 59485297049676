export default {
    title: "YOUR PROFILE",
    subTitle: "let us know you better",
    backText: 'home',
    myInfo: 'my info',
    firstName: 'first name',
    lastName: 'last name',
    japaneseFirstName: 'japanese first name',
    japaneseLastName: 'japanese last name',
    cellPhone: 'cell phone',
    birthday: 'birthday',
    nationality: 'nationality',
    gender: 'gender',
    city: 'city',
    country: 'country',
    email: 'email',
    lineId: 'line ID',
    instagramId: 'instagram ID',
    state: 'state',
    address: 'address',
    corporate: 'corporate',
    modelTypes: 'model Types',
    language: 'language',
    level: 'level',
    remove: 'remove',
    add: 'add',
    bio: 'bio',
    bio_jpn: 'japanese bio',
    updateProfile: 'update',
    footerText: 'All Right Reserved'
}