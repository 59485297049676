<template>
  <div class="wa__time">
    <div class="wa__time--time">
      <h3>{{ time }}</h3>
      <small class="wa__time--date">{{ date }}</small>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    time: "",
    date: "",
  }),
  methods: {
    getNow: function () {
      const today = new Date();
      this.time =
        this.addZero(today.getHours()) + ":" + this.addZero(today.getMinutes());
    },
    getDate: function () {
      const today = new Date();
      this.date =
        today.getDate() +
        "th " +
        new Intl.DateTimeFormat("en-US", { month: "long" }).format(today) +
        " " +
        today.getFullYear();
    },
    addZero: (num) => {
      if (Number(num) < 10) {
        return "0" + num;
      }
      return num;
    },
  },
  created() {
    this.getDate();
    this.getNow();
    setInterval(this.getNow, 2000);
  },
};
</script>
<style scoped>
.wa__time--time {
  text-align: left;
}
.wa__time--time h3 {
  font-family: "Montserrat-bold", sans-serif;
  font-size: 29px;
  margin-bottom: 0;
}
.wa__time--date {
  font-family: "Montserrat-regular", sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
  color: var(--color-gray);
  position: relative;
  top: -0.4rem;
}
@media screen and (max-width: 600px) {
  .wa__time {
    padding-right: 0;
  }
}
</style>