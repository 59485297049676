<template>
  <v-btn
    :text="textMode"
    depressed
    :height="height"
    x-small
    :loading="loading"
    :class="`custom-btn color-${color} ${bgColor}`"
    :block="block"
    :type="type"
  >
    <template v-if="icon">
      <v-icon class="mr-1" :class="icon" :size="size"></v-icon
    ></template>
    {{ text }}
    <template v-if="iconRight">
      <v-icon class="mr-1" :class="icon" :size="size"></v-icon
    ></template>
  </v-btn>
</template>
<script>
export default {
  props: {
    height: {},
    text: {},
    icon: {},
    textMode: {},
    iconRight: {},
    block: Boolean,
    size: {
      default: "small",
    },
    color: {
      default: "grey-lighten-3",
    },
    bgColor: {
      default: "grey-lighten-3",
    },
    loading: {
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-btn {
  border-radius: 3px;
  letter-spacing: 2px;
}
.WMi-filter {
  margin-right: 2px !important;
}
.btn-assign i,
.btn__modal--assign i,
.select-file i {
  padding-right: 5px;
}

.btn__modal--cancel .v-btn__content i {
  font-size: 14px !important;
  margin-right: 0 !important;
}
@import "@Styles/setup/variables";
@each $color, $value in $allColors {
  .v-btn.color-#{ "" + $color} {
    color: $value !important;
  }
  .v-btn.#{"" + $color} {
    background-color: $value !important;
  }
}
//  button size
$FontSizes: [ 12, 14, 16, 18, 20, 22, 24, 30, 32, 36, 48, 52, 60];
@each $Size in $FontSizes {
  .btn__size__#{$Size} {
    font-size: #{$Size}px !important;
  }
}
</style>
