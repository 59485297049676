export default {
    menu: 'home | about | models | talents | actors | apply | contact',
    menuDropdown: 'All | Women | Men | Kids-Babies',
    menuAboutDropdown: 'About Us | Company Profile',
    localeName: 'JP',
    title: 'model hiring',
    route: 'home | hiring',
    shooting: 'Shooting / Appearance date',
    forHowManyDays: 'For How Many Days',
    location: 'location',
    firstName: 'First Name',
    lastName: 'Last Name',
    companyName: 'Company Name',
    emailAddress: 'Email Address',
    phoneNumber: 'Phone Number',
    message: 'Message',
    rightWay: {
        title: 'the right way',
        subTitle: 'By creating an account or login into an existing account, we can offer you the best version of our service. so please provide us your login info.',
        signUp: 'sign up',
        login: 'login',
        forgot: 'forgot password'
    },
    or: 'or',
    email: 'Email Address',
    pass: 'Password',
    signUp: 'sign up',
    login: 'login',
    forgot: 'forgot password',
    quickWay: {
        title: 'the quick way',
        subTitle: 'Or if you do not intrested in having an account in our system, you can just fill this form and we will be in touch.',
        text: 'Please choose the intended use - you can select multiple item .',
        checkboxLabel: 'i want you to call me',
        uploadText: 'Please upload any file necessary',
        allowedFormat: 'allowed format PDF , PPT , Woed , PNG , JPG',
        sendButton: 'submit'
    },
    selectFile: 'select a file',
    crypto: '©2021 Liliana Models Agency, All Rights Reserved.',
    footerText: 'Privacy policy | Security policy'
};
