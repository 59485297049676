export default {
    title: 'HOME',
    subTitle: 'ダッシュボードへようこそ',
    requestTitle: "リクエスト",
    requestSubTitle: "選択したモデルを確認",
    notificationRequestText: "新しいリクエスト",
    notificationClientText: "NEW CLIENT",
    notificationModelText: "NEW MODEL",
    notificationContactusText: "NEW CONTACT",
    workOffersTitle: "私の仕事のオファー",
    workOffersSubTitle: "あなたのためのすべての要求",
    projectTitle: "プロジェクト",
    projectSubTitle: "すべての作品",
    ProfileTitle: "プロフィール",
    BookmarkTitle: "ブックマーク",
    BookmarkSubTitle: "保存済みのモデル",
    ProfileSubTitle: "すべてのデータを管理する",
    galleryTitle: "宣材写真",
    gallerySubTitle: "すべての画像",
    becomeAModelTitle: 'モデルになる',
    hiringRequestTitle: "採用リクエスト",
    hiringRequestSubTitle: "   ",
    ModelsTitle: "モデル",
    ModelsSubTitle: " ",
    ClientsTitle: "クライアント",
    ClientsSubTitle: " ",
    MessageTitle: "お問い合わせ",
    MessageSubTitle: " ",
    PortfolioTitle: 'Portfolios',
    PortfolioSubTitle: 'what we created',
    OfferTitle: '求人',
    OfferSubTitle: ' ',    
    EmailTitle: 'emails',
    EmailSubTitle: '   ',
    adminProjectTitle: 'プロジェクト',
    adminProjectSubTitle: '   ',
    brands: 'ブランド',
    settings: '設定',
    managers: 'マネージャー',
    BlogTitle: 'blog',
    BlogSubTitle: 'what we sahre',
    footerText: 'All Right Reserved'
}