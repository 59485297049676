export default  {
    subTitle: 'editing information',
    // basic info
    basicInfo: "Basic Info",
    backText: 'all models',
    firstName: 'First Name',
    lastName: 'Last Name',
    japaneseFirstName: 'Japanese first Name',
    japaneseLastName: 'Japanese last Name',
    birthday: 'birthday',
    nationality: 'NATIONALITY',
    gender: 'Gender',
    modelTypes: 'model types',
    language: 'language',
    level: 'level',
    remove: 'remove',
    add: 'add',
    jobExperience: 'job experience',
    bio: 'bio',
    skills: 'skills',
    // contact info
    contactInfo: "Contact Info",
    emailAddress: 'email address',
    cellPhone: 'cell phone',
    instagramId: 'instagram ID',
    lineId: 'line ID',
    showFollowersCount: 'show followers count',
    showSubscribersCount: 'showsubscribers count',
    youtubeId: 'youtube id',
    country: 'country',
    city: 'city',
    state: 'state',
    address: 'address',
    zipCode: 'zip code',
    nearestStation: 'nearest station',
    // physical info
    physicalInfo: 'physical info',
    hair: 'hair',
    eye: 'eye',
    weight: 'weight - kg',
    height: 'height - cm',
    bust: 'Bust - cm',
    hips: 'Hips - cm',
    waist: 'Waist - cm',
    shoes: 'shoes',
    tattoo: 'tattoo',
    // bank info
    bankInfo: 'bank info',
    bankName: 'bank name',
    branchName: 'branch name',
    accountNo: 'account no',
    accountName: 'account name',
    accountClassifications: 'account classifications',
    other: 'other',
    // visa info
    visaInfo: 'visa info',
    validUntil: 'valid until',
    typeOfVisa: 'type of visa',
    // attributes 
    attributes: 'attributes',
    // actions
    updateButton: 'update',
    backButton: 'never mind'
}