export default  {
    subTitle: '編集情報',
    // basic info
    basicInfo: "基本情報",
    backText: 'all models',
    firstName: '名',
    lastName: '姓',
    japaneseFirstName: '名（ローマ字）',
    japaneseLastName: '姓（ローマ字）',
    birthday: '誕生日',
    nationality: '国籍',
    gender: '性別',
    modelTypes: 'モデルタイプ',
    language: '言語',
    level: 'レベル',
    remove: '削除する',
    add: '追加',
    jobExperience: '職務経験',
    bio: '自己PR',
    skills: 'スキル',
    // contact info
    contactInfo: "連絡先情報",
    emailAddress: 'メールアドレス',
    cellPhone: '携帯番号',
    instagramId: 'Instagramアカウント',
    lineId: 'ラインID',
    youtubeId: 'YouTubeリンク',
    showFollowersCount: 'フォロワー数を掲示する',
    showSubscribersCount: 'フォロワー数を掲示する',
    city: '市区町村',
    country: '国',
    state: '都道府県',
    address: '番地以降の住所',
    zipCode: '郵便番号',
    nearestStation: '最寄り駅',
    // physical info
    physicalInfo: '身体的情報',
    hair: '髪の色',
    eye: '目の色',
    weight: '体重-kg',
    height: '身長-CM',
    bust: 'バスト-cm',
    hips: 'ヒップ-cm',
    waist: 'ウエスト-cm',
    shoes: '靴のサイズ',
    tattoo: '入れ墨 ',
    // bank info
    bankInfo: '銀行情報',
    bankName: '銀行名',
    branchName: '支店名',
    accountNo: '口座番号',
    accountName: 'アカウント名',
    accountClassifications: 'アカウントの分類',
    other: '他の',
    // visa info
    visaInfo: 'ビザ情報',
    validUntil: 'まで有効',
    typeOfVisa: 'ビザの種類',
    // attributes 
    attributes: '属性',
    // actions
    updateButton: 'update',
    backButton: 'never mind'
}