export default {
    title: "brands",
    subTitle: "OUR FRIENDS AND FAMILIES",
    backText: 'home',
    brands : 'brands',
    brandName : 'brand name',
    filters: 'FILTERS',
    language : 'language',
    clear: 'CLEAR',
    execute: 'EXECUTE',
    footerText: 'All Right Reserved'
}