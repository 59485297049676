export default {
    title: 'registration',
    // subTitle: '要求された情報を提供してください',
    signUp: '登録',
    returnToLogin: 'ログインに戻る',
    footerText: 'All Right Reserved',
    email: 'メールアドレス',
    firstName: '名',
    lastName: '姓',
    password: 'パスワード',
    passwordAgain: 'パスワード（再入力）',
    returnToRegistration: '登録に戻る',
    verifyEmailTitle: 'Eメールを確認します',
    verifyEmailSubTitle: 'Eメールを確認します',
    verifyCode: 'コードを確認する',
    sendCode: 'コードを送信する',
    prefrredLang: 'あなたの好きな言語は？',
    welcomeText: "ここでお会いできてうれしいです。まず、リリアナモデルでアカウントを作成しましょう。",
    codeText: '私たちがあなたのメールアドレスに送ったコードを入力してください',
};