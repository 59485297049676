export default {
    menu: 'home | models | apply | contact',
    menuDropdown: 'All | Women | Men | Kids-Babies',
    localeName: 'JP',
    title: 'modeling offer',
    offer_title: 'SONY Festival',
    email: 'Email Address',
    emailText: 'please enter the email address you entered when you | registered on liliana website.',
    emailAddress: 'email address',
    nearestStation: 'Please let me know your nearest station. 最寄駅教えてください',
    upload: 'did your appearances changed since you uploaded your images in liliana website.',
    updateImage: 'please specify the changes and please update your images in dashboard ',
    comment: 'Comment',
    crypto: '©2021 Liliana Models Agency, All Rights Reserved.',
    footerText: 'Privacy policy | Security policy',
    sendResponse: "send my response",
};