<template>
  <div class="wa__footer">
    <div class="wa__footer__brand">
      <div class="wa__footer__brand--name">
        <brand-name
          brand-name="Liliana"
          brand-subname="Models Agency"
          icon="WMi-diamond"
        ></brand-name>
      </div>
    </div>
    <div class="wa__footer__text">
      <div class="wa__footer__text--text pb-1">
        {{ text }}
      </div>
    </div>
  </div>
</template>
<script>
import BrandName from "../Header/BrandName.vue";
export default {
  components: {
    BrandName,
  },
  props: ["text"],
};
</script>
<style scoped>
.wa__home--footer {
  background-color: var(--color-light-gray);
}
.wa__footer {
  text-align: center;
}
.wa__brand {
  justify-content: center;
}
.wa__footer__text--text {
  font-size: 17px;
  margin-top: 5px;
  padding-left: 12px;
  color: var(--color-gray);
  letter-spacing: 1px;
}
.wa__footer {
  padding: 14px 0 0 0;
}
</style>