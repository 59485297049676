export default {
  title: "email templates",
  subTitle: "what we prepared",
  backText: "Emails Management",
  templates: "templates",
  filters: 'フィルター',
  controls: "controls",
  dateBefore: "date, before",
  dateAfter: "date, after",
  itemsTitle: "title",
  add: "add",
  name: "name",
  clear: "CLEAR",
  execute: "EXECUTE",
  footerText: "All Right Reserved",
  instagramId: "instagram id",
  valid_until: "valid until"
};
