<template>
  <div class="wa__tile">
    <div
      class="wa__tile__tile"
      :class="`${small ? 'wa__small__tile__tile' : ''} color-${color} ${bgColor}`"
    >
      <!-- :style="{ height: `${height}px` }" -->
      <template v-if="notificationTitle">
        <div class="wa__tile__tile__main__has__notification" style="padding-left: 40px">
          <i :class="icon"></i>
          <div class="wa__tile__tile__main__title">
            <div class="wa__tile__tile__main__title--title">
              <div>
                {{ title }}
              </div>
            </div>
            <div class="wa__tile__tile__main__title--subtitle">
              <div>
                {{ subTitle }}
              </div>
            </div>
          </div>
        </div>
        <div class="wa__tile__tile__notification d-none d-md-block position-relative">
          <!-- :style="small ? 'padding: 6px 31px 10px 14px' : 'padding: none'" -->
          <div class="wa__tile__tile__notification--box">
            <div class="wa__tile__tile__notification--quantity">
              {{ notificationTitle }}
            </div>
            <div class="wa__tile__tile__notification--subtitle text-caption">
              {{ notificationText }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="!subTitle">
        <div class="wa__mangers">
          <div class="wa__mangers__icon">
            <div class="wa__mangers__icon--icon">
              <i :class="icon" class="icon"></i>
            </div>
          </div>
          <div class="wa__mangers__title">
            <div class="wa__mangers__title--title">
              <h3>{{ title }}</h3>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="wa__tile__tile__main">
          <div class="d-flex align-center">
            <i :class="icon"></i>
            <div class="wa__tile__tile__main__title">
              <div class="wa__tile__tile__main__title--title">
                <div>{{ title }}</div>
              </div>
              <div class="wa__tile__tile__main__title--subtitle">
                <div>
                  {{ subTitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    small: {
      default: false,
    },
    notificationTitle: { default: null },
    title: { default: null },
    subTitle: { default: null },
    notificationText: { default: null },
    icon: { default: null },
    color: {
      default: "",
    },
    bgColor: {
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.wa__tile {
  display: flex;
  justify-content: center;
}
.wa__tile__tile {
  text-align: center;
  background-color: var(--color-bg-smoky);
  width: 100%;
  margin: 5px 0;
  position: relative;
  display: flex;
  justify-content: center;
  height: 115px;
  border-radius: 5px;
}
.wa__tile__tile.wa__small__tile__tile {
  height: 90px;
}
.wa__tile__tile.wa__small__tile__tile .wa__tile__tile__main div i {
  font-size: 50px;
}
.wa__tile__tile.wa__small__tile__tile
  .wa__tile__tile__main
  .wa__tile__tile__main__title
  .wa__tile__tile__main__title--title
  div {
  font-size: 1.5rem;
}
.wa__tile__tile.wa__small__tile__tile
  .wa__tile__tile__main
  .wa__tile__tile__main__title
  .wa__tile__tile__main__title--subtitle
  div {
  font-size: 0.8rem;
}
.wa__tile__tile__notification {
  font-family: "Barlow-EL";
  float: right;
  border-radius: 11px;
  background-color: black;
  text-align: left;
  // padding: 22px 31px 10px 14px;
  height: inherit;
  width: 100px;
}
.wa__tile__tile__notification--box {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
}
.wa__tile__tile__notification--quantity {
  color: var(--color-white);
  font-size: 41px;
}
.wa__tile__tile__notification--subtitle.text-caption {
  color: var(--color-white);
  font-family: "Barlow-EL" !important;
  font-size: 10px;
  position: relative;
  top: -0.7rem;
  padding: 0 0 0 3px;
  width: min-content;
  letter-spacing: 1px !important;
  line-height: 10px !important;
}
.wa__tile__tile__main__has__notification,
.wa__tile__tile__main {
  padding-top: 2px;
  padding-left: 20px;
  text-align: left;
  align-items: center;
  height: 100%;
}
.wa__tile__tile__main__has__notification {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.wa__tile__tile__main {
  display: flex;
}
.wa__tile__tile__main__title {
  display: grid;
  line-height: 26px;
}

.wa__tile__tile__main__has__notification i,
.wa__tile__tile__main i {
  font-size: 60px;
}
.wa__tile__tile__main__title--title div {
  font-family: "Barlow-EL" !important;
  font-size: 2rem;
  text-transform: uppercase;
}
.wa__tile__tile__main__title--subtitle div {
  color: var(--color-smoky);
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: 5px !important;
  text-transform: uppercase;
  // position: relative;
  font-family: "Montserrat-regular" !important;
  // top: -0.6rem;
}

.wa__mangers {
  position: absolute;
  height: min-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wa__mangers__icon {
  padding-right: 9px;
  font-size: 50px;
}
.wa__mangers__title--title h3 {
  font-size: 20px;
  margin-bottom: 0;
}
h3 {
  font-family: "Barlow-EL";
  text-transform: uppercase;
}
.icon {
  color: var(--color-black);
}
@media screen and (max-width: 1264px) {
  .wa__tile__tile__main__title--title div {
    font-size: 1.5rem;
  }
  .wa__tile__tile__main__title--subtitle div {
    letter-spacing: 1px !important;
    top: -0.4rem;
  }
  .wa__tile__tile__main__has__notification i,
  .wa__tile__tile__main i {
    font-size: 60px;
  }
  .wa__mangers__title--title h3 {
    font-size: 20px;
  }
  .wa__tile__tile {
    height: 120px;
  }
  .wa__mangers__icon {
    font-size: 39px;
  }
  .wa__tile__tile__notification {
    padding: 22px 31px 10px 14px;
  }
}
@media screen and (max-width: 600px) {
  .wa__tile__tile__main__title--title div {
    font-size: 20px;
    letter-spacing: 1px;
  }
  .wa__tile__tile__main__title--subtitle div {
    font-size: 10px;
  }
  .wa__mangers__icon {
    font-size: 29px;
  }
}
@import "@Styles/setup/variables";
@each $color, $value in $allColors {
  .color-#{"" + $color},
  .color-#{"" + $color} .wa__tile__tile__main i,
  .color-#{"" + $color} .wa__tile__tile__main__has__notification i,
  .color-#{"" + $color} .wa__tile__tile__main__title--subtitle div {
    color: $value;
  }
  .#{"" + $color} {
    background-color: $value;
  }
  .color-#{"" + $color} .wa__tile__tile__notification {
    background-color: $value;
  }
}
</style>
