<template>
  <div class="logo">
    <a :href="baseRoute">
      <img v-if="white" :src="whiteLogo" alt="liliana logo" />
      <img v-else :src="logo" alt="liliana logo" />
    </a>
  </div>
</template>
<script>
// import Logo from "../../../assets/logo_lliana.svg";
import Logo from "../../../assets/logo-black.png";
import WhiteLogo from "../../../assets/logo-w-padding.png";

export default {
  props: {
    white: {},
  },
  data() {
    return {
      logo: Logo,
      whiteLogo: WhiteLogo,
    };
  },
  computed: {
    baseRoute() {
      if (this.$_getLocale() == "en") {
        return this.$baseUrl + "/en";
      } else {
        return this.$baseUrl;
      }
    },
  },
};
</script>
<style scoped>
.logo img {
  height: 41px;
}

.site_logo a img {
  height: 53px !important;
}

.logo__image .logo a img {
  height: 55px !important;
}
@media screen and (max-width: 960px) {
  .logo img {
    height: 31px;
  }
}
@media screen and (max-width: 960px) {
  .site_logo a img {
    height: 49px !important;
  }
}
@media screen and (max-width: 600px) {
  .site_logo a img {
    height: 41px !important;
  }
}
</style>
