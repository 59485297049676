export default {
    menu: ' ホーム | 会社について | モデル | タレント | 俳優 | 登録 | お問い合わせ',
    menuDropdown: 'すべて | 女性 | 男性 | キッズ•赤ちゃん',
    menuAboutDropdown: '企業理念 | 会社概要',
    localeName: 'EN',
    title: 'model hiring',
    route: 'home | hiring',
    shooting: '撮影日',
    forHowManyDays: '拘束時間',
    location: '撮影場所',
    firstName: '名',
    lastName: '姓',
    companyName: '会社名',
    emailAddress: 'メールアドレス',
    phoneNumber: '電話番号',
    message: 'メッセージ',
    rightWay: {
        title: 'ログイン',
        subTitle: 'アカウントを作成するか、既存のアカウントにログインすることで、迅速な対応が可能です。',
        signUp: 'サインアップ',
        login: 'ログイン',
        forgot: 'パスワードが不明な方'
    },
    or: 'または',
    email: 'メールアドレス',
    pass: 'パスワード',
    signUp: 'サインアップ',
    login: 'ログイン',
    forgot: 'パスワードが不明な方',
    quickWay: {
        title: '問い合わせ',
        subTitle: 'アカウントにログインしない場合は、下記に詳細をご記入いただきましたら、順番に対応させていただきます。',
        text: 'イベント/ショー',
        checkboxLabel: '電話してほしい',
        uploadText: '必要なファイルをアップロードしてください',
        allowedFormat: '許可された形式のPDF、PPT、Woed、PNG、JPG',
        sendButton: '送信'
    },
    selectFile: 'ファイルを選択',
    crypto: '©2021 Liliana Models Agency, All Rights Reserved.',
    footerText: 'Privacy policy | Security policy'
};
