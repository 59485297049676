<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="nudgeRight"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :class="['pt-0', { site__input: site }]"
          v-model="date"
          :label="label"
          :placeholder="placeholder"
          prepend-inner-icon="mdi-calendar"
          readonly
          :color="color"
          :outlined="site || outlined ? true : false"
          :dense="site || dense ? true : false"
          v-bind="attrs"
          v-on="on"
          :rules="rules"
        />
      </template>
      <v-date-picker
        :color="color"
        v-model="date"
        :type="type"
        :max="max"
        :min="min"
        :allowed-dates="allowedDates"
        @input="menu = false"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    label: {},
    placeholder: {},
    color: {},
    site: {
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    value: {},
    type: {
      default: "date",
    },
    nudgeRight: {
      default: 40,
    },
    rules: {
      default: ()=>([v => !!v || true]),
    },
    allowedDates:{
      default: ()=>{},
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
