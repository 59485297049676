export default {
    menu: ' ホーム | モデル | 登録 | お問い合わせ',
    menuDropdown: 'すべて | 女性 | 男性 | キッズ•赤ちゃん',
    localeName: 'EN',
    title: 'Models Selection',
    projectText: '私たちはあなたのプロジェクトのためにこのモデルを集めました。 ご希望のモデルをお選びいただき、残りはお任せください。',
    height: '身長(CM)',
    bust: 'バスト(CM)',
    waist: 'ウエスト(CM)',
    hips: 'ヒップ(CM)',
    shoe: '靴',
    hair: '髪',
    eye: '目',
    age: '年',
    nationality: '国籍',
    language: '言語',
    actions: '行動',
    filter: {
        nationality: '国籍',
        language: '言語',
        eye: '目の色',
        hair: '髪の色',
        ageMin: '年齢-分',
        ageMax: '年齢-最大',
        heightMin: '高さ-分',
        heightMax: '高さ-最大',
        bustMin: 'バスト-分',
        bustMax: 'バスト-最大',
        waistMin: 'ウエスト-分',
        waitMax: 'ウエスト-最大',
        hipsMin: 'ヒップ-分',
        hipsMax: 'ヒップ-最大',
        shoeMin: '靴-分',
        shoeMax: '靴-最大',
        execute: '実行する',
        clear: 'クリア'
    },
    footerText: 'All Right Reserved',
};