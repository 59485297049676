<template>
    <div class="WM-Checkbox c-toggle-hide RTL WM-Align-R">
        <input
            type="checkbox"
            :id="rand"
            class="c-check"
            :value="valueSelected"
            v-model="values"
            @change="changeValue"
        />
        <label :for="rand">
            <span class="inc"></span>
            <span class="check"></span>
            <span class="box"></span>
            {{ showText }}
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            itemValue: {default: null},
            itemSlug: {default: 'id'},
            itemText: {default: null},
            text: {default: " "},
            item: {default: () => ({})},
            color: {default: "Red"},
            value: {type: Array}
        },
        data: function () {
            return {
                IconClass: "WMi-" + this.Icon,
                rand: Math.random(),
                values: this.computedValues(this.value)
            };
        },
        computed: {
            valueSelected() {
                if (typeof this.item == 'object') {
                    if (this.itemValue) {
                        return this.item[this.itemValue];
                    } else {
                        return this.item[this.itemSlug];
                    }
                } else {
                    return this.item;
                }
            },
            showText() {
                if (this.itemText) {
                    return this.item[this.itemText];
                }
                return this.text;
            },
        },
        methods: {
            changeValue($event) {
                if (this.value) {
                    if ($event.target.checked) {
                        if (this.itemValue) {
                            this.value.push(this.valueSelected);
                        } else {
                            this.value.push(this.item);
                        }
                    } else {
                        if (typeof this.item == 'object' && !this.itemValue) {
                            var index = this.value.findIndex(
                                x => x[this.itemSlug] == this.item[this.itemSlug]
                            );
                        } else {
                            index = this.value.findIndex(
                                x => x == this.valueSelected
                            );
                        }
                        this.value.splice(index, 1);
                    }
                }
                this.$emit("change", this.item, $event.target.checked);
            },
            computedValues(value) {
                if (Array.isArray(value)) {
                    let newArray = [];
                    for (const val of value) {
                        if (typeof val == "object") {
                            newArray.push(val[this.itemSlug]);
                        } else {
                            newArray.push(val);
                        }
                    }
                    return newArray;
                } else {
                    return value;
                }
            }
        },
        watch: {
            value(value) {
                this.values = this.computedValues(value);
            },
        }
    };
</script>
<style lang="scss" scoped>

    .WM-Checkbox {
        position: relative;
        /* handling click events */
        /* when checkbox is checked */
    }

    .WM-Checkbox.WM-Checkbox-inline {
        display: inline-block;
    }

    .form-inline .WM-Checkbox.WM-Checkbox-inline {
        margin-left: 20px;
        top: 3px;
    }

    .WM-Checkbox input[type=checkbox] {
        visibility: hidden;
        position: absolute;
    }

    .WM-Checkbox label {
        cursor: pointer;
        padding-right: 30px;
    }

    .WM-Checkbox label > span {
        display: block;
        position: absolute;
        right: 0;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }

    .WM-Checkbox label > span.inc {
        background: #fff;
        right: -10px;
        top: -10px;
        height: 40px;
        width: 40px;
        opacity: 0;
        border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        -webkit-border-radius: 50% !important;
    }

    .WM-Checkbox label > .box {
        top: 1px;
        border: 2px solid #d0d7de;
        height: 20px;
        width: 20px;
        z-index: 5;
        -webkit-transition-delay: 0.2s;
        -moz-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }

    .ie .WM-Checkbox label > .box {
        top: 2px;
    }

    .WM-Checkbox label > .check {
        top: 2px;
        left: 6px;
        width: 20px;
        height: 10px;
        border: 2px solid #ee3552;
        border-top: none;
        border-right: none;
        opacity: 0;
        z-index: 5;
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        transform: rotate(-180deg);
        -webkit-transition-delay: 0.3s;
        -moz-transition-delay: 0.3s;
        transition-delay: 0.3s;
    }

    .WM-Checkbox label > span.inc {
        -webkit-animation: growCircle 0.3s ease;
        -moz-animation: growCircle 0.3s ease;
        animation: growCircle 0.3s ease;
    }

    .WM-Checkbox input[type=checkbox]:checked ~ label > .box {
        opacity: 0;
        -webkit-transform: scale(0) rotate(180deg);
        -moz-transform: scale(0) rotate(180deg);
        transform: scale(0) rotate(180deg);
    }

    .WM-Checkbox input[type=checkbox]:checked ~ label > .check {
        opacity: 1;
        -webkit-transform: scale(1) rotate(-45deg);
        -moz-transform: scale(1) rotate(-45deg);
        transform: scale(1) rotate(-45deg);
    }

    .WM-Checkbox input[type=checkbox]:disabled ~ label,
    .WM-Checkbox input[type=checkbox][disabled] ~ label {
        cursor: not-allowed;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }

    .WM-Checkbox input[type=checkbox]:disabled ~ label > .box,
    .WM-Checkbox input[type=checkbox][disabled] ~ label > .box {
        cursor: not-allowed;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }

    .WM-Checkbox input[type=checkbox]:disabled:checked ~ label > .check,
    .WM-Checkbox input[type=checkbox][disabled]:checked ~ label > .check {
        cursor: not-allowed;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }

    .WM-Checkbox.has-error label {
        color: #e7505a;
    }

    .WM-Checkbox.has-error label > .box {
        border-color: #e7505a;
    }

    .WM-Checkbox.has-error label > .check {
        border-color: #e7505a;
    }

    .WM-Checkbox.has-info label {
        color: #4eabe6;
    }

    .WM-Checkbox.has-info label > .box {
        border-color: #4eabe6;
    }

    .WM-Checkbox.has-info label > .check {
        border-color: #4eabe6;
    }

    .WM-Checkbox.has-success label {
        color: #5dc09c;
    }

    .WM-Checkbox.has-success label > .box {
        border-color: #5dc09c;
    }

    .WM-Checkbox.has-success label > .check {
        border-color: #5dc09c;
    }

    .WM-Checkbox.has-warning label {
        color: #c8d046;
    }

    .WM-Checkbox.has-warning label > .box {
        border-color: #c8d046;
    }

    .WM-Checkbox.has-warning label > .check {
        border-color: #c8d046;
    }

</style>
