<template>
  <div class="wa__admin d-flex align-center justify-end">
    <div class="wa__admin--name text-right d-none d-md-block">
      <div class="user__name">
        <div>
          {{ $_changeModelProperty(user, "first_name") }}
        </div>
        <v-chip label x-small>{{ type }}</v-chip>
      </div>
    </div>
    <template v-if="hasLogin">
      <div class="wa__tile--link d-flex align-center ml-2">
        <router-link
          :to="{
            name: 'dashboard',
            params: { locale: this.$_getlocaleParam() },
          }"
        >
          <v-icon class="WMi-home-icon" large color="black"></v-icon>
        </router-link>
        <v-chip label @click.native="$_changeLocalePage()" class="chip__change__language"
          ><a class="change-language lili-link text-decoration-none">{{
            $_getLocale() === "jp" ? "EN" : "JP"
          }}</a></v-chip
        >
        <v-btn icon v-ripple="false" @click.native="doLogout" class="ml-4">
          <v-icon size="30" class="WMi-off-linear" color="black"></v-icon>
        </v-btn>
      </div>
    </template>
  </div>
</template>
<script>
import { TokenStorage, UserStorage } from "@/utils/storage";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      type: UserStorage.get().type,
      user: UserStorage.get(),
    };
  },
  computed: {
    hasLogin() {
      return !!TokenStorage.getToken();
    },
  },
  methods: {
    async doLogout() {
      const response = await this.logout();
      if (response) {
        this.$router.push({
          name: "login",
          params: { locale: this.$_getlocaleParam() },
        });
      }
    },
    ...mapActions("auth", ["logout"]),
  },
};
</script>
<style scoped>
.wa__tile--link a {
  text-decoration: none;
  display: block;
  color: initial;
}

.wa__tile--link {
  cursor: pointer;
}

.wa__admin--name {
  display: flex;
  align-items: flex-end;
}

.wa__admin--name h6 {
  font-size: 15px;
  text-transform: uppercase;
}
.chip__change__language {
  font-family: "montserrat-regular";
  font-size: 18px;
}
.wa__tile--link i {
  font-size: 25px;
  padding-right: 5px;
}
.user__name {
  border-right: 1px solid var(--color-light-gray);
  padding-right: 10px;
  line-height: 17px;
  font-family: "montserrat-regular" !important;
  text-transform: uppercase;
}
</style>
