export default {
    required: "this field is required",
    requiredPassword: "The password field is required",
    requiredEmail: "The Email field is required",
    min_one_digit: "At least one digit",
    min_one_capital: "At least one capital latter",
    just_japanese: "Please enter Japanese.",
    just_english: "Please enter English.",
    valid_english: "Please enter a valid English name.",
    valid_email: "Please enter a valid email name.",
    name_length: "length should be greater than 1 character.",
    choice_one_item: "You should choice one item.",
    enter_just_digit: "you should enter just digit.",
    number_between_0_1000: "The number must be greater than 0 and less than 1000.",
    number_between_15_280: "The number must be greater than 1.5 and less than 280.",
    number_between_30_250: "The number must be greater than 30 and less than 250.",
    number_between_30_180: "The number must be greater than 30 and less than 180.",
    number_between_8_40:  "The number must be greater than 8 and less than 40.",
    name: "First name and last name can't be empty",
    err_upload_image_msg: "You should upload at least one image.",
}
