import Vue from "vue";
import "./utils/globalVariable";
import store from "./store";
import router from "./router";
import App from "./App.vue";

import vuetify from "./plugins/vuetify";
import "./utils/globalComponents";
import "./styles/global.scss";
import ApiService from '@/utils/axios/api';
Vue.config.productionTip = false;

ApiService.init(Vue.prototype.$baseUrl);

import '@/utils/axios/errorHandler';

import "./mixins/global";
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");


