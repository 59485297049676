<template>
  <div class="wa__header">
    <v-row class="mb-4">
      <v-col cols="12" sm="3" class="d-none d-md-block"> <Time /></v-col>
      <v-col cols="6" md="6" class="text-left text-md-center"><brand-name /></v-col>
      <v-col cols="6" lg="3" class="text-right"><HeaderIcons /></v-col>
    </v-row>
    <div class="wa__header--bottom">
      <template v-if="backText">
        <router-link
          v-if="backRouteName"
          :to="{ name: backRouteName, params: { locale: $_getlocaleParam() } }"
          class="d-none d-md-block"
        >
          <back icon="WMi-left-open" :back-text="backText" />
        </router-link>
        <back
          v-else
          @click.native="$emit('backEvent')"
          icon="WMi-left-open"
          class="d-none d-md-flex"
          :back-text="backText"
          style="cursor: pointer"
        />
      </template>
      <divider :title="title" :sub-title="subTitle" size="none" />
    </div>
  </div>
</template>
<script>
import HeaderIcons from "./HeaderIcons.vue";
import Time from "./Time.vue";
import BrandName from "./BrandName.vue";
export default {
  components: {
    HeaderIcons,
    Time,
    BrandName,
  },
  data() {
    return {
      brandName: "Liliana",
      brandSubName: "Models Agency",
    };
  },
  props: {
    title: {},
    subTitle: {},
    backRouteName: {
      default: "dashboard",
    },
    backText: {
      default: null,
    },
  },
};
</script>
<style scoped>
.wa__header--top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.wa__header--bottom {
  text-align: center;
  position: relative;
  padding: 25px 0 10px 0;
}
.wa__header--bottom a {
  color: inherit;
}
.vertical {
  color: #616161;
}
</style>
