export default {
    title: 'become a model',
    // subTitle: 'モデリングの世界への参入をお手伝いします',
    backText: 'home',
    requestSend: 'Thank You.',
    requestSendText1: 'Your modeling request has',
    requestSendText2: 'been successfully submitted.',
    requestSendFinish: 'Once we finished reviewing your <br /> information you will receive an email <br /> with the result.',
    editTheRequest: 'return to your dashboard',
};
