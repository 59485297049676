export default {
    menu: 'home | models | apply | contact',
    menuDropdown: 'All | Women | Men | Kids-Babies',
    localeName: 'JP',
    title: 'Liliana job offer',
    subTitle: 'full summery',
    offerTitle: 'Thank You.',
    offerResponse: 'Your respond to our job offer was submitted.',
    offertext: 'Once we finished reviewing your information you will receive an email with the result.',
    returnDashboard: 'return to liliana website'
}