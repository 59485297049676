export default {
    title: "hiring requests",
    // subTitle: "モデルに対するすべてのリクエスト",
    backText: 'home',
    filters: 'フィルター',
    modelName: 'モデル名',
    userName: 'ユーザー名',
    status: '状態',
    dateAfter: '日付、後',
    dateBefore: '日付、前',
    clear: 'クリア',
    execute: '検索',
    requests: 'リクエスト',
    requester: 'リクエスター',
    model: 'モデル',
    dateAndStatus: '日付/ステータス',
    controls: 'コントロール',
    // request detail modal     
    requestDetail: {
        title: 'リクエスト詳細',
        subTitle: 'THE DETAILS YOU NEED',
        dateBegin: '撮影開始日',
        requiredDays: '期間',
        description: '内容',
        location: '場所',
        callMe: '電話希望',
        downloadFile: 'ファイル名',
        modelTypes: 'モデルタイプ',
        fullName: 'フルネーム',
        gender: '性別',
        totalJob: '案件実施数',
    },
    closeButton: 'BACK',
    // footer 
    footerText: 'All Right Reserved'
}