export default {
    title: "project list",
    subTitle: "歴史を振り返ってみましょう",
    backText: 'home',
    filters: 'フィルター',
    modelName: 'モデル名',
    userName: 'ユーザー名',
    dateAfter: '日付、後',
    dateBefore: '日付、前',
    clear: 'クリア',
    execute: '実行する',
    projects: 'プロジェクト',
    name: '名前',
    timeLine: 'タイムライン',
    cost: '費用',
    controls: 'コントロール',
    footerText: 'All Right Reserved'
}