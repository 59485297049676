export default {
    title: "Emails Management",
    subTitle: "what we sent to others",
    backText: "home",
    emails: "emails",
    filters: 'フィルター',
    controls: "controls",
    recipient: "recipient",
    dateBefore: "date, before",
    dateAfter: "date, after",
    itemsTitle: "title",
    add: "add",
    emailTitle: "email title",
    clear: "CLEAR",
    execute: "EXECUTE",
    footerText: "All Right Reserved",
    instagramId: "instagram id",
    valid_until: "valid until"
};
