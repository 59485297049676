<template>
  <div class="wa__back">
    <i :class="icon"></i>
    <h6 class="ml-1">{{ backText }}</h6>
  </div>
</template>
<script>
export default {
  props: ["icon", "backText"],
};
</script>
<style scoped>
.wa__back {
  font-family: "Barlow-EL", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: baseline;
  position: absolute;
  top: 50%;
  letter-spacing: 2px;
}
.wa__back i {
  font-size: 13px;
}
</style>
