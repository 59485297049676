<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="pt-0 mt-0"
          v-model="time"
          :label="label"
          readonly
          :prepend-inner-icon="icon"
          :color="color"
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          :rules="rules"
        >
          <!-- <template #append>
            <v-icon>{{ icon }}</v-icon>
          </template> -->
        </v-text-field>
      </template>
      <v-time-picker
        :dark="dark"
        :min="min"
        :max="max"
        :color="color"
        v-model="time"
        :type="type"
        :format="format"
        @input="menu = false"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    label: {},
    placeholder: {},
    color: {},
    value: {},
    min: {},
    max: {},
    rules : {},
    icon: {
      default: "WMi-clock-1",
    },
    format: {
      default: "24hr",
    },
    type: {
      default: "time",
    },
    dark: {
      default: false,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    time: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
