export default {
    title: "project list",
    subTitle: "let's review the history",
    backText: 'home',
    filters: 'FILTERS',
    modelName: 'model name',
    userName: 'user name',
    dateAfter: 'date, after',
    dateBefore: 'date, before',
    clear: 'CLEAR',
    execute: 'EXECUTE',
    projects: 'projects',
    name: 'name',
    timeLine: 'timeline',
    cost: 'cost',
    controls: 'controls',
}