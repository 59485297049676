export default {
    title: "YOUR PROFILE",
    // subTitle: "私たちにあなたをもっとよく知らせてください",
    backText: 'home',
    myInfo: '私の情報',
    firstName: '名（ローマ字）',
    lastName: '姓（ローマ字）',
    japaneseFirstName: '名（日本語）',
    japaneseLastName: '姓（日本語）',
    cellPhone: '携帯電話',
    birthday: '誕生日',
    nationality: 'エスニック',
    gender: '性別',
    city: '市',
    country: '国',
    email: 'Eメール',
    lineId: 'ラインID',
    instagramId: 'Instagramアカウント',
    state: '都道府県',
    address: '住所',
    corporate: '企業',
    modelTypes: 'モデルタイプ',
    language: '言語',
    level: 'レベル',
    remove: '削除する',
    add: '追加',
    bio: '自己PR（英語）',
    bio_jpn: '自己PR（日本語）',
    updateProfile: 'アップデート',
    footerText: 'All Right Reserved'
}