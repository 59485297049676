export default {
    menu: ' ホーム | モデル | 登録 | お問い合わせ',
    menuDropdown: 'すべて | 女性 | 男性 | キッズ•赤ちゃん',
    localeName: 'EN',
    title: 'modeling offer',
    email: 'メールアドレス',
    offer_title: 'ソニーフェスティバル',
    emailText: 'リリアナのウェブサイトに登録したときに入力したメールアドレスを入力してください。',
    emailAddress: '電子メールアドレス',
    nearestStation: '最寄りの駅を教えてください。 最寄駅教えてください',
    upload: 'リリアナのウェブサイトに画像をアップロードしてから、外観は変わりましたか？',
    updateImage: '変更を指定し、ダッシュボードで画像を更新してください',
    comment: 'コメント',
    sendResponse: "私の応答を送信します",
    crypto: '©2021 Liliana Models Agency, All Rights Reserved.',
    footerText: 'Privacy policy | Security policy'
};