export default {
    title: 'become a model',
    // subTitle: 'モデル登録',
    backText: 'home',
    step: 'ステップ',
    step1: { text: "基本情報", next: 'NEXT' },
    step2: { text: "連絡先情報", next: 'NEXT', prev: 'BACK' },
    step3: { text: "身体的情報", next: 'NEXT', prev: 'BACK' },
    step4: { textBank: "銀行情報",textVisa:"ビザ情報", next: 'NEXT', prev: 'BACK' },
    step5: { text: "最終チェック", next: 'SUBMIT', prev: 'BACK' },
    profile: 'プロフィール画像',
    emailAddress: 'メールアドレス',
    cellPhone: '携帯番号',
    instagramId: 'Instagramアカウント',
    lineId: 'ラインID',
    youtubeId: 'YouTubeリンク',
    city: '市区町村',
    country: '国',
    state: '都道府県',
    address: '番地以降の住所',
    zipCode: '郵便番号',
    nearestStation: '最寄り駅',
    firstName: '名（日本語）',
    lastName: '姓（日本語）',
    japaneseFirstName: '名（ローマ字）',
    japaneseLastName: '姓（ローマ字）',
    birthday: '誕生日',
    nationality: '国籍',
    gender: '性別',
    modelTypes: 'モデルタイプ',
    language: '言語',
    level: 'レベル',
    remove: '削除する',
    add: '追加',
    jobExperience: '職務経験',
    jobExperienceHint: 'モデル経験がある方は具体的にご記載ください。行替えはしないでください。',
    tattooHint: 'タトゥーがある場合は、大きさと場所を詳しくご記載ください。',
    bioHint: '行替えせずご記載ください。',
    bio: '自己PR',
    skills: 'スキル',
    hair: '髪の色',
    eye: '目の色',
    weight: '体重-kg',
    height: '身長-CM',
    bust: 'バスト-cm',
    hips: 'ヒップ-cm',
    waist: 'ウエスト-cm',
    shoes: '靴のサイズ',
    tattoo: '入れ墨 ',
    bankName: '銀行名',
    branchName: '支店名',
    accountNo: '口座番号',
    accountName: 'アカウント名',
    validUntil: 'まで有効',
    accountClassifications: 'アカウントの分類',
    other: 'その他 (記入自由)',
    typeOfVisa: 'ビザの種類',
    footerText: 'All Right Reserved',
    apartment: 'アパート・マンション名',
    showFollowersCount: 'フォロワー数を掲示する',
    showSubscribersCount: 'フォロワー数を掲示する',
};
