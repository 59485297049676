export default {
    title: 'Home',
    subTitle: 'WELCOME TO YOUR HOME DASHBOARD',
    requestTitle: "MY REQUESTS",
    requestSubTitle: "All Request For You",
    notificationRequestText: "NEW REQUEST",
    notificationClientText: "NEW CLIENT",
    notificationModelText: "NEW MODEL",
    notificationContactusText: "NEW CONTACT",
    workOffersTitle: "MY WORK OFFERS",
    workOffersSubTitle: "All Request For You",
    projectTitle: "MY PROJECTS",
    projectSubTitle: "All My Works",
    ProfileTitle: "My Profile",
    ProfileSubTitle: "Manage All Your Data",
    BookmarkTitle: "My bookmarks",
    BookmarkSubTitle: "what you saved",
    galleryTitle: "my galleray",
    gallerySubTitle: "all your images",
    becomeAModelTitle: 'become a model',
    hiringRequestTitle: "HIRING REQUESTS",
    hiringRequestSubTitle: "ALL REQUEST FOR YOUR MODELS",
    ModelsTitle: "My Models",
    ModelsSubTitle: "MANAGE ALL YOUR MODELS",
    ClientsTitle: "users",
    ClientsSubTitle: "THE TRUE ASSET",
    MessageTitle: "contact us",
    MessageSubTitle: "those who contacted us",
    PortfolioTitle: 'Portfolios',
    PortfolioSubTitle: 'what we created',
    OfferTitle: 'Job Offers',
    OfferSubTitle: 'what we propose',
    EmailTitle: 'emails',
    EmailSubTitle: 'what we sent',
    adminProjectTitle: 'projects',
    adminProjectSubTitle: 'all works',
    brands: 'BRANDS',
    settings: 'SETTINGS',
    managers: 'Managers',
    BlogTitle: 'blog',
    BlogSubTitle: 'what we sahre',
    footerText: 'All Right Reserved'
}