export default {
    required: "この欄は必須です。",
    requiredPassword: "The password field is required",
    requiredEmail: "The Email field is required",
    min_one_digit: "最低1文字の数字を含んでください。",
    min_one_capital: "最低1文字の大文字アルファベットを含んでください。",
    just_japanese: "日本語で入力してください。",
    just_english: "英語で入力してください。",
    valid_english: "Please enter a valid English name.",
    valid_email: "Please enter a valid email name.",
    name_length: "length should be greater than 1 character.",
    choice_one_item: "1項目選んでください。",
    enter_just_digit: "数字だけ入力してください。",
    number_between_0_1000: "0 以上 1000 未満であること。",
    number_between_15_280: "1.5 以上 280 未満であること。",
    number_between_30_250: "30 以上 250 未満であること。",
    number_between_30_180: "30 以上 180 未満であること。",
    number_between_8_40: "8 以上 40 未満であること。",
    name: "First name and last name can't be empty",
    err_upload_image_msg: "You should upload at least one image.",
}
