export default {
    menu: 'home | models | apply | contact',
    menuDropdown: 'All | Women | Men | Kids-Babies',
    localeName: 'JP',
    title: 'Models Selection',
    projectText: '私たちはあなたのプロジェクトのためにこのモデルを集めました。 ご希望のモデルをお選びいただき、残りはお任せください。',
    height: 'height (CM)',
    bust: 'bust (CM)',
    waist: 'waist (CM)',
    hips: 'hips (CM)',
    shoe: 'shoe',
    hair: 'hair',
    eye: 'eye',
    age: 'age',
    nationality: 'nationality',
    language: 'language',
    actions: 'Actions',
    filter: {
        nationality: 'nationality',
        language: 'language',
        eye: 'eye color',
        hair: 'hair color',
        ageMin: 'age - min',
        ageMax: 'age - max',
        heightMin: 'height - min',
        heightMax: 'height - max',
        bustMin: 'bust - min',
        bustMax: 'bust - max',
        waistMin: 'waist - min',
        waistMax: 'waist - max',
        hipsMin: 'hips - min',
        hipsMax: 'hips - max',
        shoeMin: 'shoe - min',
        shoeMax: 'shoe - max',
        execute: 'execute',
        clear: 'clear'
    },
    footerText: 'All Right Reserved',
};