<template>
  <v-menu bottom left v-if="items.length" nudge-bottom="47" nudge-right="11">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        color="black"
        class="mt-0"
        :x-small="xs"
        :small="sm"
        :large="lg"
        :x-large="xl"
      >
        <v-icon dark>{{ icon }}</v-icon>
      </v-btn>
    </template>

    <v-list class="CursorPointer model__list__item bg-gray py-0">
      <template v-for="(item, index) in items">
        <router-link
          class="text-decoration-none"
          v-if="item.route"
          :to="{
            name: item.route.routeName,
            params: item.route.params ? {...item.route.params} : {},
          }"
          :key="index"
        >
          <v-list-item>
            <v-list-item-icon class="mr-1">
              <v-icon small color="black" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-item v-else @click.native="click(item)" :key="index">
          <v-list-item-icon class="mr-1">
            <v-icon small color="black" v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-0" :key="'A' + index"></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    items: { default: () => [] },
    icon: { default: "WMi-ellipsis-vert" },
    xs: { default: false, type: Boolean },
    sm: { default: false, type: Boolean },
    lg: { default: false, type: Boolean },
    xl: { default: false, type: Boolean },
  },
  methods: {
    click(item) {
      if (typeof item.click !== "undefined") {
        item.click(this.$parent);
      }
    },
  },
};
</script>
<style>
.CursorPointer {
  cursor: pointer;
}
.v-list hr:last-child {
  display: none;
}
.v-menu__content {
  background-color: #e7e7e7 !important;
}
.bg-gray {
  background-color: #e7e7e7 !important;
}
.model__list__item .v-list-item__title {
  font-family: "montserrat-light" !important;
  font-size: 12px !important;
}
</style>
