export default {
    title: 'reset password',
    subTitle: ' please set your new password',
    send: 'send',
    returnToLogin: 'return to login',
    back: 'back',
    email: 'email address',
    footerText: 'All Right Reserved',
    password: 'new password',
    verifyCode : 'verify code',
    sendCode : 'send code',
    reset : 'reset'
};