export default {
    title: 'reset password',
    subTitle: ' please set your new password',
    send: '送信',
    returnToLogin: 'ログインに戻る',
    back: '戻る',
    email: 'メールアドレス',
    footerText: '無断複写・All Right Reserved転載を禁じます',
    password: 'あなたのパスワード',
    verifyCode : 'コードを確認する',
    sendCode : 'コードを送信する',
    reset : 'reset'
};