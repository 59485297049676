export default {
    title: 'become a model',
    subTitle: 'let us help you entering the modeling world',
    backText: 'home',
    step: 'step',
    step1: { text: "Basic Info", next: 'next step : contact info' },
    step2: { text: "Contact Info", next: 'next step : physical info', prev: 'return to basic info' },
    step3: { text: "physical Info", next: 'next step : bank & visa info', prev: 'return to contact info' },
    step4: { textBank: "bank Info",textVisa:"visa Info", next: 'next step: final check', prev: 'return to physical info' },
    step5: { text: "final check", next: 'send the data for approval', prev: 'edit data' },
    profile: 'profile image',
    emailAddress: 'email address',
    cellPhone: 'cell phone',
    instagramId: 'instagram ID',
    lineId: 'line ID',
    youtubeId: 'youtube id',
    city: 'city',
    country: 'country',
    state: 'state',
    address: 'address',
    zipCode: 'zip code',
    nearestStation: 'nearest station',
    firstName: 'first Name',
    lastName: 'last Name',
    japaneseFirstName: 'Japanese first Name',
    japaneseLastName: 'Japanese last Name',
    birthday: 'birthday',
    nationality: 'NATIONALITY',
    gender: 'Gender',
    modelTypes: 'model types',
    language: 'language',
    level: 'level',
    remove: 'remove',
    add: 'add',
    jobExperience: 'job experience',
    bio: 'bio',
    skills: 'skills',
    hair: 'hair',
    width: 'width',
    eye: 'eye',
    weight: 'weight - kg',
    height: 'height - cm',
    bust: 'Bust - cm',
    hips: 'Hips - cm',
    waist: 'Waist - cm',
    shoes: 'shoe',
    tattoo: 'tatoo',
    bankName: 'bank name',
    branchName: 'branch name',
    accountNo: 'account no',
    accountName: 'account name',
    validUntil: 'valid until',
    accountClassifications: 'account classifications',
    other: 'any other matter you want us to know (Optional)',
    typeOfVisa: 'type of visa',
    footerText: 'All Right Reserved',
    apartment: 'apartment',
    showFollowersCount: 'show followers count',
    showSubscribersCount: 'showsubscribers count',
};
