<template>
  <v-btn
    :x-small="xs"
    :small="sm"
    :large="lg"
    :x-large="xl"
    icon
    class="circle-button"
    :class="`color-${color} ${bgColor}`"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    icon: {},
    color: {
      default: "grey-lighten-3",
    },
    bgColor: {
      default: "bgGray",
    },
    xs: Boolean,
    sm: Boolean,
    lg: Boolean,
    xl: Boolean,
  },
};
</script>
<style lang="scss" scoped>
.circle-button {
  padding: 21px;
}
.circle-button:not(:last-child) {
  margin-right: 7px;
}
.circle-button i,
.circle-button i,
.circle-button i,
.circle-button i,
.circle-button i {
  font-size: 19px !important  ;
}
@import "@Styles/setup/variables";
@each $color, $value in $allColors {
  .v-btn.color-#{"" + $color} i {
    color: $value !important;
  }
  .v-btn.#{"" + $color} {
    background-color: $value !important;
  }
}
</style>
