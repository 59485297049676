export default {
    del_request_item_msg: "Do you want to delete Request?",
    del_request_item_title: "Delete Request",
    del_portfolio_item_msg: "Are you sure you want to delete this portfolio?",
    del_portfolio_item_title: "Delete Portfolio",
    del_offer_response_msg: "Are sure delete offer response?",
    del_offer_response_title: "Delete response",
    del_offer_item_msg: "Are sure delete offer?",
    del_offer_item_title: "Delete Offer",
    del_model_item_msg: "Are sure delete Model?",
    del_model_item_title: "Delete Model",
    del_manager_item_msg: "Are sure delete manager?",
    del_manager_item_title: "Delete Manager",
    del_blog_item_msg: "Are you sure you want to delete this blog?",
    del_blog_item_title: "Delete Blog",
    del_bookmark_item_msg: "Are sure you want remove this model from bookmarks?",
    del_bookmark_item_title: "Remove Bookmark",
    del_brand_item_msg: "Are sure delete brand?",
    del_brand_item_title: "Delete Brand",
    del_client_item_msg: "Are sure delete User?",
    del_client_item_title: "Delete User",
    del_email_template_item_msg: "Are sure delete email template?",
    del_email_template_item_title: "Delete Template",
    del_gallery_img_item_msg: "Are you sure you want remove this image?",
    del_gallery_img_item_title: "Remove Image",
    verify_model_msg: "Are you sure you want to convert this user to a model?",
    verify_model_title: "Verify To Be A Model",
    append_update_msg: "Are you sure you want to accept changes of model?",
    append_update_title: "Accept Change Model Data",
    become_model_return_msg: "Are you sure you want to return?",
    become_model_return_title: "Confirm return",
    approve_job_offer_title: "Approve Response(s)",
    approve_job_offer_msg: "Are sure you want approve response(s)?",
    reject_job_offer_title: "reject Response(s)",
    reject_job_offer_msg: "Are sure you want reject response(s)?",
    copy_link_msg: "link copied successfully.",
    copy_link_title: "success",
    send_to_client_response_msg:
      "Are you sure you want send selected models link to client for approval?",
    send_to_client_response_title: "send?",
    // ! Delete multi models
    del_multi_models_title: "Delete Model(s)",
    del_multi_models_msg: "Are you sure you want delete this models?",
  };
