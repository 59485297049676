// https://vuex.vuejs.org/en/modules.html

const requireModule = require.context('.', true, /\.js$/)
let locales = {};
requireModule.keys().forEach(fileName => {
    if (fileName === './index.js') return;

    const path = fileName.replace(/(\.\/|\.js)/g, '');
    const [lang, file] = path.split('/');
    if (!locales[lang]) {
        locales[lang] = {};
    }
    locales[lang][file] = requireModule(fileName).default;

});
export default locales
