export default {
    title: 'registration',
    subTitle: 'provide us requested info',
    signUp: 'register',
    returnToLogin: 'return to login',
    footerText: 'All Right Reserved',
    email: 'email address',
    firstName: 'first name',
    lastName: 'last name',
    password: 'password',
    passwordAgain: 'retype password',
    returnToRegistration: 'return to registration',
    verifyEmailTitle: 'verify email',
    verifyEmailSubTitle: 'verify email',
    verifyCode: 'verify code',
    sendCode: 'send code',
    prefrredLang: 'Your PREFRRED LANGAUGE?',
};