export default {
    title: 'MY MODELS',
    subTitle: 'ALL THE BEAUTIES AROUND YOU',
    backText: 'home',
    cellphoneOrName: 'name / cell phone',
    models: 'MODELS',
    status: 'status',
    jobs: 'jobs',
    controls: 'controls',
    sendEmail: 'send email',
    hairColor: 'hair color',
    eyeColor: 'eye color',
    languageLevel: 'language level',
    language: 'language',
    country: 'country',
    nationality: 'nationality',
    modelType: 'Please Specify your most intended uses',
    gender: 'gender',
    dateBefore: 'date, before',
    dateAfter: 'date, after',
    verify: 'verify',
    modelFirstName: 'model first name',
    modelLastName: 'model last name',
    modelLastNameJapanese: 'model last name japanese',
    modelFirstNameJapanese: 'model first name japanese',
    modelId: 'model id',
    filters: 'FILTERS',
    city: 'city',
    email: 'email',
    phone: 'phone',
    sortBy: 'sort by',
    clear: 'CLEAR',
    clearAll: 'CLEAR ALL',
    selectAll: 'Select ALL',
    execute: 'EXECUTE',
    footerText: 'All Right Reserved',
    instagramId: 'instagram id',
    valid_until: 'valid until',
    placeHolder: 'PLACE HOLDERS',
    modelVerifications: 'Model Verifications',
};
