export default {
    menu: ' ホーム | モデル | 登録 | お問い合わせ',
    menuDropdown: 'すべて | 女性 | 男性 | キッズ•赤ちゃん',
    localeName: 'EN',
    title: 'リリアナの求人',
    subTitle: '完全な要約',
    offerTitle: 'ありがとうございます。',
    offerResponse: '私たちの求人へのあなたの応答が提出されました。',
    offertext: '情報の確認が完了すると、結果が記載されたメールが届きます。',
    returnDashboard: 'リリアナのウェブサイトに戻る'
}