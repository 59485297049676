export default {
    title: 'MY MODELS',
    subTitle: 'LET\'S DECIDE HOW DO WHAT',
    backText: 'home',
    managers : 'managers',
    requests : 'requests',
    cellphoneOrName : 'name / cell phone',
    jobs : 'jobs',
    controls : 'controls',
    dateBefore : 'date, before',
    dateAfter : 'date, after',
    filters: 'FILTERS',
    managerName : 'manager name',
    clear: 'CLEAR',
    execute: 'EXECUTE',
    footerText: 'All Right Reserved',
};