<template>
  <div class="wa__filters" :class="{ wa__social__filters: filter }" v-if="filter">
    <v-expansion-panels
      accordion
      v-if="
        this.$vuetify.breakpoint.name !== 'lg' && this.$vuetify.breakpoint.name !== 'xl'
      "
    >
      <v-expansion-panel class="wa__v-expansion-panel">
        <v-expansion-panel-header class="wa__filters--title">{{
          title
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <div class="wa__filters--title pt-3" v-if="title">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
  <div class="wa__filters h-100" :class="{ wa__social__filters: filter }" v-else>
    <div class="d-flex justify-space-between" v-if="title">
      <div class="wa__filters--title">{{ title }}</div>
      <div>
        <template>
          <slot name="header-btn"></slot>
        </template>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "",
    },
    filter: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.wa__filters {
  background-color: var(--color-bg-white);
  border: 1px solid var(--color-gray);
  border-radius: 8px;
  padding: 13px 12px 10px 12px;
}

.wa__social__filters {
  padding: 0 17px;
}

.wa__filters--title {
  font-family: "Barlow-EL", sans-serif;
  letter-spacing: 10px;
  color: var(--color-black);
  font-size: 19px;
  margin-bottom: 2px;
  text-transform: uppercase;
}

.wa__large {
  position: relative;
  padding: 13px 30px 10px 27px;
}

.wa__large__page {
  height: auto;
}
@media screen and (max-width: 960px) {
  .wa__filters {
    min-height: auto;
  }

  .wa__filters--title {
    letter-spacing: 2px;
    font-size: 13px;
  }

  .wa__social__filters {
    padding: 0 7px;
  }
}

@media screen and (max-width: 600px) {
  .wa__filters {
    padding: 10px;
  }

  .wa__large {
    padding: 13px 15px 10px 13px;
  }
}
</style>
<style>
.v-expansion-panel-header {
  padding: 10px 13px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 12px 0 !important;
}
</style>
