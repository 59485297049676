export default {
    filters: 'filters',
    backText: 'home',
    title: 'Blog',
    subTitle: 'what we shared',
    filterTitle: 'title',
    modelTagged: 'model tagged',
    dateAfter: 'date, after',
    dateBefore: 'date, before',
    clear: 'clear',
    execute: 'execute'
}