export default {
    title: "hiring requests",
    subTitle: "All Request For Your Models",
    backText: 'home',
    filters: 'FILTERS',
    modelName: 'model name',
    userName: 'user name',
    status: 'status',
    dateAfter: 'date, after',
    dateBefore: 'date, before',
    clear: 'CLEAR',
    execute: 'EXECUTE',
    requests: 'requests',
    requester: 'requester',
    model: 'model',
    dateAndStatus: 'date / status',
    controls: 'controls',
    // request detail modal 

    requestDetail: {
        title: 'request details',
        subTitle: 'THE DETAILS YOU NEED',
        dateBegin: 'DATE BEGIN',
        requiredDays: 'REQUIRED DAYS',
        description: 'DESCRIPTION',
        location: 'LOCATION',
        callMe: 'CALL ME',
        downloadFile: 'file name',
        modelTypes: ' model types',
        fullName: 'full name',
        gender: 'gender',
        totalJob: 'TOTAL JOBS'
    },
    closeButton: "IT'S ALLRIGHT, THANK YOU",
    // footer 
    footerText: 'All Right Reserved'
}