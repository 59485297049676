export default {
    title: "users",
    subTitle: "THE TRUE ASSET",
    backText: 'home',
    users : 'users',
    cellphoneOrName : 'name / cell phone',
    request :'request',
    role :'role',
    controls : 'controls',
    clientFirstName: 'model first name',
    clientLastName: 'model last name',
    clientLastNameJapanese: 'model last name japanese',
    clientFirstNameJapanese: 'model first name japanese',
    clientEmail : 'user email',
    clientRole : 'user role',
    filters: 'FILTERS',
    dateAfter: 'date, after',
    dateBefore: 'date, before',
    country : 'country',
    clear: 'CLEAR',
    clearAll: 'CLEAR ALL',
    selectAll: 'SELECT ALL',
    execute: 'EXECUTE',
    footerText: 'All Right Reserved',
    sendEmail : 'send email',
}
